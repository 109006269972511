import React from "react"
import styled from "styled-components"
import H2 from "./typography/H2"
import P from "./typography/P"
import media from "../utils/mediaQueries"
import { textShadow } from "../utils/shadows"
import { fontExtraBold } from "../utils/fontStyles"
import EasyResultsGraphic from "./staticQuery/EasyResultsGraphic";

const { se, desktop } = media

const DragAndDrop = () => (
  <PageWrapper>
    <PageContainer>
      <TextContainer>
        <StyledH2>Easy As Drag & Drop</StyledH2>
        <P>
          We've built MindMe to be as simple as possible to keep you as efficient as possible. Use our drag and drop builder to build beautiful Mobile Pages and HTML Emails in no time.
        </P>
      </TextContainer>
      <ImageWrapper>
      <EasyResultsGraphic /> 
      </ImageWrapper>
    </PageContainer>
  </PageWrapper>
)

export default DragAndDrop

const PageWrapper = styled.section`
  will-change: opacity, transform;

  width: 100%;
  height: 100%;
  ${desktop && desktop`
    padding: 50px;
`}
`

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 2;
  ${se &&
    se`
    padding: 50px 20px;
    width: 100%;
    flex-direction: column;
  `}

  ${desktop &&
    desktop`
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    max-width: 1200px;
    padding: 0;
    height: 80%;
    width: 100%;
  `}
`
const ImageWrapper = styled.div`
  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
    order: 1;
    `}

  ${desktop &&
    desktop`
    margin-bottom: 0px;
    order: 2;
  `}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
 
  z-index: 2;
  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
    order: 2;
  `}

  ${desktop &&
    desktop`
    width: 80%;
    margin-bottom: 0px;
    order: ${({ imageRight }) => (imageRight ? "1" : "2")};
  `}
`

const StyledH2 = styled(H2)`
  ${fontExtraBold()};
  ${textShadow()};
  font-size: 36px;
`
