import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import H1 from "./typography/H1"
import P from "./typography/P"
import media from "../utils/mediaQueries"
import InternalButton from "./layout/internalbutton"
import { textShadow, buttonShadow } from "../utils/shadows"
import HeroImage from "../components/staticQuery/HeroImage"
const { se, desktop } = media

const Hero = () => (
  <div style={{ position: "relative" }}>
    <HeroWrapper>
      <HeroContainer>
        <TextContainer>
          <StyledH1>Mobile Marketing Just Got Awesome…</StyledH1>
          <StyledP>The only marketing solution you’ll ever need</StyledP>
          <InternalButton link="/beta" title="Request Your Free Account" />
        </TextContainer>
      </HeroContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon fill="white" points="0,100 100,0 100,100" />
      </svg>
    </HeroWrapper>
    <ImageContainer>
      <StyledPlayer
        url="https://vimeo.com/348087822/e6053f2896"
        loop
        playing
        controls="false"
        muted
        width="100%"
        height="100%"
      />
    </ImageContainer>
  </div>
)

export default Hero

const HeroWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(1, 93, 181, 0.15);
  position: relative;
  overflow-x: hidden;

  ${se &&
    se`
   padding: 50px 0 50px 0;
   height: 75vh;
  `}
  ${desktop &&
    desktop`
    height: 600px;
  `}

svg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10vw;
    /* set height to pixels if you want angle to change with screen width */
  }
`

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 2;
  ${se &&
    se`
    padding: 0 20px;
    width: 100%;
    flex-direction: column;
  `}

  ${desktop &&
    desktop`
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    padding: 0;
  `}
`

const ImageContainer = styled.div`
  position: absolute;
  z-index: 2;
  margin: 0 auto;
  left: 0;
  right: 0;
  ${se &&
    se`
    width: 80%;
    height: 35%;
    bottom: -50px;
  `}

  ${desktop &&
    desktop`
    bottom: -200px;
    max-width: 650px;
    margin: 0 auto;
    width: 100%;
    height: 61%;
  `}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${se &&
    se`
    width: 100%;
    margin-bottom: 100px;
  `}

  ${desktop &&
    desktop`
    margin-bottom: 0px;
  `}
`

const StyledH1 = styled(H1)`
  text-align: center;
  ${textShadow()};

  ${se &&
    se`
font-size: 30px;
`}

  ${desktop &&
    desktop`
  margin-bottom: 15px;
  font-size: 37px;
  `}
`

const StyledP = styled(P)`
  text-align: center;

  ${se &&
    se`
  font-size: 18px;
`}

  ${desktop &&
    desktop`
  margin-bottom: 30px;
  font-size: 24px;
  `}
`

const StyledPlayer = styled(ReactPlayer)`
  margin-bottom: 70px;
  ${buttonShadow()}

  ${se &&
    se`
  
  `}
`
