import React from "react"
import styled from "styled-components"
import H2 from "./typography/H2"
import P from "./typography/P"
import media from "../utils/mediaQueries"
import { textShadow } from "../utils/shadows"
import { fontExtraBold } from "../utils/fontStyles"
import ConsolidateToolsGraphic from "./staticQuery/ConsolidateToolsGraphic"

const { se, desktop } = media

const AllOfYourTools = () => (
  <PageWrapper>
    <PageContainer>
      <ImageWrapper>
        <ConsolidateToolsGraphic />
      </ImageWrapper>
      <TextContainer>
        <StyledH2>All of Your Tools, One Place</StyledH2>
        <P>
          You no longer have to juggle different applications and services to
          manage your Contacts and outbound communication. Keep all of your
          assets, contacts, communication and stats in one place.
        </P>
      </TextContainer>
    </PageContainer>
  </PageWrapper>
)

export default AllOfYourTools

const PageWrapper = styled.section`
  will-change: opacity, transform;
  width: 100%;
  height: 100%;

  ${desktop &&
    desktop`
    padding: 50px;
`}
`

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 2;
  ${se &&
    se`
    padding: 50px 20px;
    width: 100%;
    flex-direction: column;
  `}

  ${desktop &&
    desktop`
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    max-width: 1200px;
    padding: 0;
    height: 80%;
    width: 100%;
  `}
`
const ImageWrapper = styled.div`
  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
    order: 2;
    `}

  ${desktop &&
    desktop`
    margin-bottom: 0px;
    order: 2;
  `}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  order: ${({ imageRight }) => (imageRight ? "1" : "2")};
  z-index: 2;
  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
  `}

  ${desktop &&
    desktop`
    width: 80%;
    margin-bottom: 0px;
  `}
`

const StyledH2 = styled(H2)`
  ${fontExtraBold()};
  ${textShadow()};
  font-size: 36px;
`
