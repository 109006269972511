import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero";
import Stats from "../components/stats";
import Testimonial from "../components/testimonial";
import PowerfulFeatures from "../components/powerfulfeatures";
import Process from "../components/process";
import MessageAcross from "../components/messageacross";
import AllOfYourTools from "../components/allofyourtools";
import DragAndDrop from "../components/draganddrop";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Process />
    {/* <Stats /> */}
    <Testimonial />
    <MessageAcross />
    <AllOfYourTools />
    <DragAndDrop />
    <PowerfulFeatures />
  </Layout>
)

export default IndexPage
