import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { black } from '../../utils/colors'
import { fontExtraBold } from '../../utils/fontStyles'

const H4 = ({ children, align, className }) => (
  <StyledH4 align={align} className={className}>
    {children}
  </StyledH4>
)

H4.defaultProps = {
  align: 'left',
  className: '',
}

H4.propTypes = {
  children: PropTypes.string.isRequired,
  align: PropTypes.string,
  className: PropTypes.string,
}

export default H4

const StyledH4 = styled.h4`
  ${fontExtraBold()}

  font-size: 20px;
  line-height: 1.5;
  color: ${black};
  text-align: ${({ align }) => align && align};
`
