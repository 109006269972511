import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ConsolidateToolsGraphic = () => {
  const data = useStaticQuery(graphql`
    query {
      consolidateToolsGraphicImage: file(relativePath: { eq: "graphics/consolidate-tools-graphic.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Img
      fluid={data.consolidateToolsGraphicImage.childImageSharp.fluid}
      alt="Consolidate Tools Graphic"
    />
  )
}

export default ConsolidateToolsGraphic
