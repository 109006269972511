import React, { useState, useCallback } from "react"
import { useTrail, animated } from "react-spring"
import styled from "styled-components"
import H2 from "./typography/H2"
import H4 from "./typography/H4"
import P from "./typography/P"
import media from "../utils/mediaQueries"
import { textShadow } from "../utils/shadows"
import { borderGrey, white, text, textLight } from "../utils/colors"
import { fontBold, fontSemiBold } from "../utils/fontStyles"
import Quote from "./typography/Quote"
import DragAndDropIcon from "../images/icons/drop-and-drop.svg"
import VideosAudioImageIcon from "../images/icons/video-audio-image-documents.svg"
import VideosFromFacebookIcon from "../images/icons/videos-from-youtube-facebook.svg"
import CommunicationOnTheGoIcon from "../images/icons/communication-on-the-go.svg"
import RoyaltyFreeImagesIcon from "../images/icons/royalty-free-images.svg"
import CommunicationByEmailSMSIcon from "../images/icons/communication-by-email-sms.svg"
import ContactSegmentationIcon from "../images/icons/contact-segmentation.svg"
import SmartListsIcon from "../images/icons/smart-lists.svg"
import ScheduledRepeatCampaignsIcon from "../images/icons/scheduled-repeat-campaigns.svg"
import SmartRemindersIcon from "../images/icons/smart-reminders.svg"
import MobileFirstDesignIcon from "../images/icons/mobile-first-design.svg"
import LeadCaptureIcon from "../images/icons/lead-capture-via-web-sms-social-media.svg"
import DeliverEngagingContentIcon from "../images/icons/deliver-engaging-content.svg"
import SendDigitalOffersIcon from "../images/icons/send-digital-offers.svg"
import ActionsBasedOnResponsesIcon from "../images/icons/actions-based-on-responses.svg"
import ShareSuccessfulCampaignsIcon from "../images/icons/share-successful-campaigns-with-colleagues.svg"
import AdvancedReportsAndAnalyticsIcon from "../images/icons/advanced-reports-and-analytics.svg"
import MultipleUsersIcon from "../images/icons/multiple-users.svg"
import MultipleLocationsIcon from "../images/icons/multiple-locations.svg"
import CommunicationBasedOnPreferencesIcon from "../images/icons/communcation-based-on-preference.svg"
import MarketingAutomationIcon from "../images/icons/marketing-automation-over-time.svg"
import GetToKnowAudienceIcon from "../images/icons/get-to-know-audience.svg"
import CombineCampaignsForContestsIcon from "../images/icons/combine-campaigns-for-contests.svg"
import CountDownIcon from "../images/icons/create-urgency-with-countdown.svg"
import ConsolidateToolsGraphic from "./staticQuery/ConsolidateToolsGraphic"

const { se, tablet, desktop } = media

const mainItems = [
  {
    icon: DragAndDropIcon,
    title: "Easy-to-Use Drag & Drop Builder",
    description:
      "Build Mobile Pages and Emails so quickly that you'll be spoiled.",
  },
  {
    icon: VideosAudioImageIcon,
    title: "Video, Audio, Image & Document Hosting",
    description: "Keep all of your business's assets organized in one place.",
  },
  {
    icon: VideosFromFacebookIcon,
    title: "Add Videos from Facebook, YouTube, Vimeo, Wistia",
    description: "Add videos to basically anything, from basically anywhere.",
  },
  {
    icon: CommunicationOnTheGoIcon,
    title: "Communicate on the Go",
    description: "Take 90% of MindMe's features on the go with our iOS app.",
  },
  {
    icon: RoyaltyFreeImagesIcon,
    title: "Use Over 1 Million Royalty Free Images",
    description:
      "Make everything look beautiful with our integration to Pixabay.",
  },
  {
    icon: CommunicationByEmailSMSIcon,
    title: "Communicate by Email, SMS & MMS",
    description: "Reach your customers anywhere they want to be reached.",
  },
  {
    icon: ContactSegmentationIcon,
    title: "Contact Segmentation with Lists, Interests, Tags",
    description:
      "Organize your Contact List to send the right messages to the right people.",
  },
  {
    icon: SmartListsIcon,
    title: "Create Smart Lists for Easy Targeting",
    description: "Smart Lists basically organize your Contact book for you.",
  },
  {
    icon: ScheduledRepeatCampaignsIcon,
    title: "Scheduled & Repeating Campaigns",
    description:
      "Make Campaigns as perfect as possible by sending at the perfect time.",
  },
  {
    icon: SmartRemindersIcon,
    title: "Use Smart Reminders to Improve Deliverability",
    description:
      "So long are the days of unread emails when customers get text reminders.",
  },
  {
    icon: MobileFirstDesignIcon,
    title: "Mobile First Design",
    description: "To allow everything you send to look good on a phone screen.",
  },
  {
    icon: LeadCaptureIcon,
    title: "Lead Capture via Web, SMS, Social Media & Phone",
    description: "Yes, you can basically capture new Leads from anywhere.",
  },
  {
    icon: DeliverEngagingContentIcon,
    title: "Deliver Engaging Content with Mobile Landing Pages",
    description:
      "Keep your pages engaging with videos, interactive elements, and more.",
  },
  {
    icon: SendDigitalOffersIcon,
    title: "Send Digital Offers & Track Redemptions",
    description: "Because everyone loves to be sent a redeemable coupon.",
  },
  {
    icon: ActionsBasedOnResponsesIcon,
    title: "Create Actions based on Customer Responses",
    description: "Generate more insight that you ever knew you needed.",
  },
]

const easeOfUseItems = [
  {
    icon: DragAndDropIcon,
    title: "Easy-to-Use Drag & Drop Builder",
    description:
      "Build Mobile Pages and Emails so quickly that you'll be spoiled.",
  },
  {
    icon: VideosAudioImageIcon,
    title: "Video, Audio, Image & Document Hosting",
    description: "Keep all of your business's assets organized in one place.",
  },
  {
    icon: VideosFromFacebookIcon,
    title: "Add Videos from Facebook, YouTube, Vimeo, Wistia",
    description: "Add videos to basically anything, from basically anywhere.",
  },
  {
    icon: CommunicationOnTheGoIcon,
    title: "Communicate on the Go",
    description: "Take 90% of MindMe's features on the go with our iOS app.",
  },
  {
    icon: RoyaltyFreeImagesIcon,
    title: "Use Over 1 Million Royalty Free Images",
    description:
      "Make everything look beautiful with our integration to Pixabay.",
  },
  {
    icon: ShareSuccessfulCampaignsIcon,
    title: "Share Successful Campaigns & Content with Colleagues",
    description:
      "Shareable Libraries allow you to recycle really awesome content.",
  },
  {
    icon: AdvancedReportsAndAnalyticsIcon,
    title: "Advanced Reporting & Analytics at your Fingertips",
    description: "Get stats that are more than static, but rather actionable.",
  },
  {
    icon: MultipleUsersIcon,
    title: "Multiple Users with Security & Roles",
    description:
      "Give the right access to the right people managing your business.",
  },
  {
    icon: MultipleLocationsIcon,
    title: "Organize Multiple Locations or Brands with Sub-Accounts",
    description: "Yes, you can use one account to manage it all.",
  },
]

const deliverabilityItems = [
  {
    icon: CommunicationByEmailSMSIcon,
    title: "Communicate by Email, SMS & MMS",
    description: "Reach your customers anywhere they want to be reached.",
  },
  {
    icon: ContactSegmentationIcon,
    title: "Contact Segmentation with Lists, Interests, Tags",
    description:
      "Organize your Contact List to send the right messages to the right people.",
  },
  {
    icon: SmartListsIcon,
    title: "Create Smart Lists for Easy Targeting",
    description: "Smart Lists basically organize your Contact book for you.",
  },
  {
    icon: ScheduledRepeatCampaignsIcon,
    title: "Scheduled & Repeating Campaigns",
    description:
      "Make Campaigns as perfect as possible by sending at the perfect time.",
  },
  {
    icon: SmartRemindersIcon,
    title: "Use Smart Reminders to Improve Deliverability",
    description:
      "So long are the days of unread emails when customers get text reminders.",
  },
  {
    icon: CommunicationBasedOnPreferencesIcon,
    title: "Communicate based on Customer Preference",
    description:
      "Ask customers how they want to be contacted so you can increase open rates.",
  },
  // {
  //   icon: DragAndDropIcon,
  //   title: "Manage Website/Call Volume with Batch Send",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te",
  // },
]

const engagementItems = [
  {
    icon: MobileFirstDesignIcon,
    title: "Mobile First Design",
    description: "To allow everything you send to look good on a phone screen.",
  },
  {
    icon: LeadCaptureIcon,
    title: "Lead Capture via Web, SMS, Social Media & Phone",
    description: "Yes, you can basically capture new Leads from anywhere.",
  },
  {
    icon: DeliverEngagingContentIcon,
    title: "Deliver Engaging Content with Mobile Landing Pages",
    description:
      "Keep your pages engaging with videos, interactive elements, and more.",
  },
  {
    icon: SendDigitalOffersIcon,
    title: "Send Digital Offers & Track Redemptions",
    description: "Because everyone loves to be sent a redeemable coupon.",
  },
  {
    icon: ActionsBasedOnResponsesIcon,
    title: "Create Actions based on Customer Responses",
    description: "Generate more insight that you ever knew you needed.",
  },
  // {
  //   icon: MarketingAutomationIcon,
  //   title: "Marketing Automation over Time",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te",
  // },
  {
    icon: GetToKnowAudienceIcon,
    title: "Get to Know your Audience with Questions, Polls, Feedback",
    description:
      "Use different types of Call to Actions to get insight from your Campaigns.",
  },
  // {
  //   icon: GetToKnowAudienceIcon,
  //   title: "Send & Receive RSVP’s with Reminders",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te",
  // },
  // {
  //   icons: CombineCampaignsForContestsIcon,
  //   title: "Combine Campaigns & Offers to Create Contests",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te",
  // },
  {
    icon: CountDownIcon,
    title: "Create Urgency with Countdown",
    description:
      "Add Countdowns to Emails and Mobile Pages to use urgency to your advantage.",
  },
]

const PowerfulFeatures = () => {
  const [items, setItems] = useState(mainItems)
  const setMain = useCallback(() => setItems(mainItems), [])
  const setEase = useCallback(() => setItems(easeOfUseItems), [])
  const setDeliveribility = useCallback(() => setItems(deliverabilityItems), [])
  const setEngagement = useCallback(() => setItems(engagementItems), [])

  const trail = useTrail(items.length, {
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <PowerfulFeaturesWrapper>
      <PowerfulFeaturesContainer>
        <Test>
          <StyledH2>Powerful Features</StyledH2>
          <StyledP>
            With all of these features, MindMe may seriously be the only
            marketing platform you'll ever need.
          </StyledP>
        </Test>
        <ButtonWrapper>
          <Button onClick={setMain} active={items === mainItems}>
            Main
          </Button>
          <Button onClick={setEase} active={items === easeOfUseItems}>
            Ease of Use
          </Button>
          <Button
            onClick={setDeliveribility}
            active={items === deliverabilityItems}
          >
            Deliverability
          </Button>
          <Button onClick={setEngagement} active={items === engagementItems}>
            Engagement
          </Button>
        </ButtonWrapper>
        <FeatureGrid>
          {trail.map((props, index) => (
            <GridItem key={items[index]} style={props}>
              <StyledIcon src={items[index].icon} alt={items[index].title} />
              <StyledH4>{items[index].title}</StyledH4>
              <StyledP>{items[index].description}</StyledP>
            </GridItem>
          ))}
        </FeatureGrid>
      </PowerfulFeaturesContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon fill="white" points="100,0 0,0 0,100" />
      </svg>
    </PowerfulFeaturesWrapper>
  )
}

export default PowerfulFeatures

const StyledIcon = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto 10px auto;
`

const PowerfulFeaturesWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  background-color: rgba(1, 93, 181, 0.15);
  ${se &&
    se`
   padding: 150px 0 50px 0;
  `}
  ${desktop &&
    desktop`
  `}
  svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 10vw;
    /* set height to pixels if you want angle to change with screen width */
  }
`

const PowerfulFeaturesContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 2;
  ${se &&
    se`
    padding: 0 20px;
    width: 100%;
    flex-direction: column;
  `}

  ${desktop &&
    desktop`
    align-items: center;
    
    max-width: 1200px;
    padding: 0;
  `}
`

const Test = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Button = styled.button`
  height: 50px;
  cursor: pointer;
  appearance: none;
  border: none;
  color: ${({ active }) => (active ? `${text}` : `${textLight}`)};
  font-family: "Open Sans", sans-serif;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  background-color: ${({ active }) => (active ? `${white}` : "transparent")};
  border-radius: ${({ active }) => (active ? `20px` : "0")};
  box-shadow: ${({ active }) =>
    active
      ? `0px 15px 30px rgba(0,0,0,0.15),0px 2px 5px rgba(0,0,0,0.08)`
      : "none"};
  transition: all 800ms ease;

  &:focus {
    outline: none;
  }

  ${se &&
    se`
width: 200px;
`}
  ${desktop &&
    desktop`
    width: 150px;
  `}
`

const ButtonWrapper = styled.span`
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;

  ${se &&
    se`
  flex-direction: column;
  `}

  ${desktop &&
    desktop`
   width: 800px;
   flex-direction: row;
  `}
`

const StyledH2 = styled(H2)`
  text-align: center;
  ${fontBold()};
  ${textShadow()};
  font-size: 47px;
`

const StyledH4 = styled(H4)`
  font-size: 18px;
  margin-bottom: 10px;
`

const StyledP = styled(P)`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
`

const FeatureGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`

const GridItem = styled(animated.div)`
  
  border-radius: 10px;
  border: 1px solid ${borderGrey};
  background-color: ${white};
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;

  ${se &&
    se`
    padding: 20px;
  `}

${tablet &&
  tablet`
width: 48%
`}
  ${desktop &&
    desktop`
  width: 30%;
  padding: 30px;
  `}
`
