import React from "react"
import styled from "styled-components"
import H2 from "./typography/H2"
import P from "./typography/P"
import media from "../utils/mediaQueries"
import { textShadow } from "../utils/shadows"
import { fontExtraBold } from "../utils/fontStyles"
import MessageDeliveredGraphic from "./staticQuery/MessageDeliveredGraphic"

const { se, desktop } = media

const MessageAcross = () => (
  <PageWrapper>
    <PageContainer>
      <TextContainer>
        <StyledH2>Get Your Message Across</StyledH2>
        <P>
         Reach your customers wherever they are. Send beautiful HTML emails with engaging elements like videos, countdown, and more! You can also send SMS Campaigns to ensure your message doesn't get lost.
        </P>
      </TextContainer>
      <ImageWrapper>
        <MessageDeliveredGraphic />
      </ImageWrapper>
    </PageContainer>
  </PageWrapper>
)

export default MessageAcross

const PageWrapper = styled.section`
  will-change: opacity, transform;
  width: 100%;
  height: 100%;
${desktop && desktop`
    padding: 50px;
`}
`

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 2;
  ${se &&
    se`
    padding: 50px 20px;
    width: 100%;
    flex-direction: column;
  `}

  ${desktop &&
    desktop`
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    max-width: 1200px;
    padding: 0;
    height: 80%;
    width: 100%;
  `}
`
const ImageWrapper = styled.div`
  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
    order: 1;
    `}

  ${desktop &&
    desktop`
    margin-bottom: 0px;
    order: 2;
  `}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  z-index: 2;
  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
    order: 2;
    `}

  ${desktop &&
    desktop`
    order: ${({ imageRight }) => (imageRight ? "1" : "2")};
    width: 80%;
    margin-bottom: 0px;
  `}
`

const StyledH2 = styled(H2)`
  ${fontExtraBold()};
  ${textShadow()};
  font-size: 36px;
`
