import React, { useState, useCallback, useEffect } from "react"
import { useTransition, animated } from "react-spring"
import styled from "styled-components"
import H2 from "./typography/H2"
import P from "./typography/P"
import useInterval from "../utils/hooks/useInterval"
import useWindowSize from "../utils/hooks/useWindowSize"
import media from "../utils/mediaQueries"
import { white, text, textLight, borderGrey } from "../utils/colors"
import { textShadow, buttonShadow } from "../utils/shadows"
import { fontRegular } from "../utils/fontStyles"
import CheckIcon from "../images/check-icon.svg"
import CaptureBorderImage from "../images/capture-border.svg"
import BorderImage from "../images/border.svg"
import CaptureGraphic from "../components/staticQuery/CaptureGraphic"
import ConvertGraphic from "./staticQuery/ConvertGraphic"
import OrganizeGraphic from "./staticQuery/OrganizeGraphic"
import EngageGraphic from "./staticQuery/EngageGraphic"
const { se, desktop } = media

const pages = [
  ({ style }) => <Capture style={{ ...style }} />,
  ({ style }) => <Convert style={{ ...style }} />,
  ({ style }) => <Organize style={{ ...style }} />,
  ({ style }) => <Engage style={{ ...style }} />,
]

const Process = () => {
  const [index, setIndex] = useState(0)

  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  const [delay] = useState(4000)
  const [isLooping, setIsLooping] = useState(true)

  const goToOne = useCallback(() => setIndex(0), [])
  const goToTwo = useCallback(() => setIndex(1), [])
  const goToThree = useCallback(() => setIndex(2), [])
  const goToFour = useCallback(() => setIndex(3), [])
  const transitions = useTransition(index, p => p, {
    from: { transform: "translate3d(100%,0,0)" },
    enter: { transform: "translate3d(0%,0,0)" },
    leave: { transform: "translate3d(-100%,0,0)" },
    config: { duration: 2000, tension: 100, friction: 100 },
  })

  useInterval(
    () => {
      if (index > pages.length) {
        setIndex(0)
      } else {
        setIndex(state => (state + 1) % 4)
      }
    },
    isLooping ? delay : null
  )

  function handlePause() {
    setIsLooping(false)
  }
  function handleResume() {
    setIsLooping(true)
  }

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <>
      <Wrapper onMouseEnter={handlePause} onMouseLeave={handleResume}>
        {transitions.map(({ item, props, key }) => {
          const Page = pages[item]
          return <Page key={key} style={props} />
        })}
        <ButtonWrapper>
          <Button onClick={goToOne} active={index === 0}>
            Capture
          </Button>
          <Button onClick={goToTwo} active={index === 1}>
            Convert
          </Button>
          <Button onClick={goToThree} active={index === 2}>
            Organize
          </Button>
          <Button onClick={goToFour} active={index === 3}>
            Engage
          </Button>
        </ButtonWrapper>
      </Wrapper>
      <MobileWrapper>
        <Capture />
        <Convert />
        <Organize />
        <Engage />
      </MobileWrapper>
    </>
  )
}

export default Process

const Capture = ({ style }) => (
  <PageWrapper style={{ ...style }}>
    <PageContainer>
      <TextContainer imageRight>
        <StyledH2>
          <span style={{ color: "#ff9c6c" }}>Capture</span> Leads from
          Everywhere
        </StyledH2>
        <P>
          MindMe makes it easy for you to grow your marketing lists for both
          your existing customers, as well as new prospects. Here are a few ways
          you can grow your list:
        </P>
        <StyledList>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Text Opt-In
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Web Opt-In
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            App/Manual
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Responsive Elements
          </StyledListItem>
        </StyledList>
      </TextContainer>
      <ImageWrapper imageRight>
        <CaptureGraphic />
      </ImageWrapper>
    </PageContainer>
    <CaptureBorder src={CaptureBorderImage} alt="" />
  </PageWrapper>
)

const Convert = ({ style }) => (
  <PageWrapper style={{ ...style }}>
    <PageContainer>
      <TextContainer>
        <StyledH2>
          <span style={{ color: "#df32ff" }}>Convert</span> Leads into Customers
        </StyledH2>
        <P>
          Our marketing platform is designed to be quick, easy and engaging to
          your customers. Wow your list by keeping them current on any new
          specials, or periodically check in.
        </P>
        <StyledList>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Mobile Pages
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Mobile Offers
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Automation
          </StyledListItem>
        </StyledList>
      </TextContainer>
      <ImageWrapper>
        <ConvertGraphic />
      </ImageWrapper>
    </PageContainer>
    <ConvertBorder src={BorderImage} alt="" />
  </PageWrapper>
)

const Organize = ({ style }) => (
  <PageWrapper style={{ ...style }}>
    <PageContainer>
      <TextContainer imageRight>
        <StyledH2>
          <span style={{ color: "#00a76b" }}>Organize</span> Customers for
          Better Results
        </StyledH2>
        <P>
          Managing a contact list doesn't have to be a chore. MindMe makes it
          easy for you to quickly (and automatically) organize your contact list
          so you always send the right communication to the right people.
        </P>
        <StyledList>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Contact Management (types, lists, interests, tags)
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Smart Lists
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Actions based on Responses
          </StyledListItem>
        </StyledList>
      </TextContainer>
      <ImageWrapper imageRight>
        <OrganizeGraphic />
      </ImageWrapper>
    </PageContainer>
    <OrganizeBorder src={BorderImage} alt="" />
  </PageWrapper>
)

const Engage = ({ style }) => (
  <PageWrapper style={{ ...style }}>
    <PageContainer>
      <TextContainer>
        <StyledH2>
          <span style={{ color: "#6fa0ff" }}>Engage</span> Customers for Repeat
          Purchases
        </StyledH2>
        <P>
          Interacting with your customers is key to your success. Because of
          that, we have added multiple ways to communicate and get your message
          across...beautifully. Getting started is as easy as drag and drop.
        </P>
        <StyledList>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Multi-Route Campaigns
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Smart Reminders
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Responsive Communication
          </StyledListItem>
          <StyledListItem>
            <StyledCheck src={CheckIcon} alt="Check Icon" />
            Mobile Offers
          </StyledListItem>
        </StyledList>
      </TextContainer>
      <ImageWrapper>
        <EngageGraphic />
      </ImageWrapper>
    </PageContainer>
    <EngageBorder src={CaptureBorderImage} alt="" />
  </PageWrapper>
)

const PageWrapper = styled(animated.div)`
  background-color: ${white};
`

const MobileWrapper = styled.div`
  ${se &&
    se`
  padding-top: 100px;
`}

  ${desktop &&
    desktop`
  display: none;
`}
`

const CaptureBorder = styled.img`
  ${se &&
    se`
display: none;
`}
  ${desktop &&
    desktop`
    display: block;
    position: absolute;
    right: 0;
    opacity: 0.3;
  `}
`
const ConvertBorder = styled.img`
  ${se &&
    se`
display: none;
`}
  ${desktop &&
    desktop`
    display: block;
    position: absolute;
    left: 0;
    opacity: 0.3;
  `}
`
const OrganizeBorder = styled.img`
  ${se &&
    se`
display: none;
`}
  ${desktop &&
    desktop`
    display: block;
    position: absolute;
    left: 0;
    opacity: 0.3;
  `}
`
const EngageBorder = styled.img`
  ${se &&
    se`
display: none;
`}
  ${desktop &&
    desktop`
    display: block;
    position: absolute;
    left: 0;
    opacity: 0.3;
  `}
`

const ImageWrapper = styled.div`
  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
    order: 1;
    `}

  ${desktop &&
    desktop`
    width: 45%;
    margin-bottom: 0px;
    order: ${({ imageRight }) => (imageRight ? "2" : "1")};
  `}
`

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 2;
  ${se &&
    se`
    padding: 0px 20px;
    width: 100%;
    flex-direction: column;
    `}

  ${desktop &&
    desktop`
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    max-width: 1200px;
    padding: 0;
    height: 60%;
    width: auto;
  `}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  z-index: 2;
  ${se &&
    se`
    width: 100%;
    margin-bottom: 50px;
    order: 2;
    `}

  ${desktop &&
    desktop`
    width: 45%;
    margin-bottom: 0px;
    order: ${({ imageRight }) => (imageRight ? "1" : "2")};
  `}
`

const Button = styled.button`
  width: 150px;
  height: 50px;
  cursor: pointer;
  appearance: none;
  border: none;
  color: ${({ active }) => (active ? `${text}` : `${textLight}`)};
  font-family: "Open Sans", sans-serif;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  background-color: ${({ active }) => (active ? `${white}` : "none")};
  border-radius: ${({ active }) => (active ? `20px` : "0")};
  box-shadow: ${({ active }) =>
    active
      ? `0px 15px 30px rgba(0,0,0,0.15),0px 2px 5px rgba(0,0,0,0.08)`
      : "none"};
  transition: all 800ms ease;

  &:focus {
    outline: none;
  }
`

const Wrapper = styled.div`
  ${se &&
    se`
 display: none;
`}

  ${desktop &&
    desktop`
    display: block;
  padding-top: 150px;
  height: 100vh;
  margin-bottom: 40px;
  background: ${white};
  position: relative;
  z-index: 1;

  & > div {
    position: absolute;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    font-size: 4em;
    will-change: transform, opacity;
  }
  `}
`

const StyledH2 = styled(H2)`
  ${textShadow()}
`

const StyledList = styled.ul`
  padding-left: 0;
  margin-left: 0;
`

const StyledListItem = styled.li`
  list-style: none;
  color: ${text};
  ${fontRegular};
  font-size: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
`

const StyledCheck = styled.img`
  margin-bottom: 0;
  margin-right: 10px;
`

const ButtonWrapper = styled.span`
  ${se &&
    se`
   display: none;
  `}

  ${desktop &&
    desktop`
    position: absolute;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    top: 28%;
    left: 0;
    right: 0;
    width: 800px;
    display: flex;
    justify-content: space-around;
  `}
`
