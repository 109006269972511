import React from "react"
import styled from "styled-components"
import H2 from "./typography/H2"
import H4 from "./typography/H4"
import P from "./typography/P"
import media from "../utils/mediaQueries"
import { textShadow } from "../utils/shadows"
import { brandPrimary } from "../utils/colors"
import { fontBold } from "../utils/fontStyles"
import Quote from "./typography/Quote"
import Conni from "./staticQuery/Connie"
import Cara from "./staticQuery/Cara"

const { se, desktop } = media

const Testimonial = () => (
  <TestimonialWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <polygon fill="white" points="100,0 0,0 0,100" />
    </svg>
    <TestimonialContainer>
      <Test>
        <StyledH2>See What People Are Saying</StyledH2>
        <StyledQuote>
          As a digital marketing consultant, I work with clients on their email
          marketing. I have worked with Constant Contact, MailChimp, Klayvio,
          Inbox25, and many others. I found working with MindMe to be incredibly
          easy and intuitive. There is almost no learning curve, so you can jump
          right in and start creating impactful campaigns. MindMe has all of the
          capabilities of other marketing automation platforms but it's much
          more user-friendly. If you're looking for a platform for all your
          email and text marketing needs, I highly recommend MindMe
        </StyledQuote>
        <StyledH4>Cara Chatellier</StyledH4>
        <StyledP>Founder, Bubbly Creative</StyledP>
      </Test>
      <div></div>
    </TestimonialContainer>
    <ImageWrapper>
      <Cara />
    </ImageWrapper>
  </TestimonialWrapper>
)

export default Testimonial

const TestimonialWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: rgba(87, 172, 45, 0.15);
  ${se &&
    se`
   padding: 50px 0 0 0;
  `}
  ${desktop &&
    desktop`
    align-items: center;
  `}

svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 10vw;
    /* set height to pixels if you want angle to change with screen width */
  }

  ${se &&
    se`
  height: 600px;
`}

  ${desktop &&
    desktop`
 height: 80vh;
`}
`

const ImageWrapper = styled.div`
  position: absolute;

  right: 0;

  ${se &&
    se`
  width: 200px;
  bottom: -60px;
  `}

  ${desktop &&
    desktop`
  width: 400px;
  bottom: 0;
  `}
`

const TestimonialContainer = styled.div`
  display: flex;
  z-index: 2;
  position: relative;
  ${se &&
    se`
    padding: 0 20px;
    width: 100%;
    flex-direction: row;
    margin: 20px auto;
  `}

  ${desktop &&
    desktop`
    align-items: center;
    justify-content: space-around;
    max-width: 1200px;
    padding: 0;
    height: 100%;
    margin: auto;
  `}
`

const Test = styled.div`
  ${desktop &&
    desktop`
     display: flex;
    flex-direction: column;
    width: 48%;
    justify-content: space-around;
    height: 50%;
  `}
`

const StyledH2 = styled(H2)`
  text-align: left;
  ${fontBold()};
  color: ${brandPrimary};
  ${textShadow()};
  font-size: 35px;
  margin-bottom: 0;
`

const StyledQuote = styled(Quote)`
  margin-bottom: 15px;
`

const StyledH4 = styled(H4)`
  font-size: 24px;
  margin-bottom: 0;
`

const StyledP = styled(P)`
  font-size: 16px;
  margin-bottom: 0;
`
