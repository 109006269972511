import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { black } from "../../utils/colors"
import { fontRegular } from "../../utils/fontStyles"
import media from "../../utils/mediaQueries"
import QuoteImage from "../../images/quote-image.svg"

const { se, desktop } = media

const Quote = ({ children, align, className }) => (
  <QuoteWrapper>
    <QuoteImg src={QuoteImage} alt="quote icon top" topQuote />
    <StyledQuote align={align} className={className}>
      {children}
    </StyledQuote>
    <QuoteImg src={QuoteImage} alt="quote icon close" />
  </QuoteWrapper>
)

Quote.defaultProps = {
  align: "left",
}

Quote.propTypes = {
  children: PropTypes.string.isRequired,
  align: PropTypes.string,
}

export default Quote

const QuoteWrapper = styled.div`
  position: relative;
`

const QuoteImg = styled.img`
  margin: 0;
  position: absolute;
  top: ${({ topQuote }) => (topQuote ? "-40px" : "")};
  right: ${({ topQuote }) => (topQuote ? "" : "0px")};
  bottom: ${({ topQuote }) => (topQuote ? "" : "-40px")};
  left: ${({ topQuote }) => (topQuote ? "-40px" : "")};
`

const StyledQuote = styled.blockquote`
  ${fontRegular()}

  line-height: 1.71;
  margin-right: 0;
  margin-left: 0;
  color: ${black};
  font-style: normal;
  ${se &&
    se`
    font-size: 15px;
    text-align: left;
  `}

  ${desktop &&
    desktop`
    font-size: 20px;
    text-align: ${({ align }) => align && align};
    
  `}
`
