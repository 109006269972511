import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { black } from '../../utils/colors'
import { fontExtraBold } from '../../utils/fontStyles'
import media from '../../utils/mediaQueries'

const { se, desktop } = media

const H2 = ({ children, align, className }) => (
  <StyledH2 align={align} className={className}>
    {children}
  </StyledH2>
)

H2.defaultProps = {
  align: 'left',
  className: '',
}

H2.propTypes = {
  children: PropTypes.string.isRequired,
  align: PropTypes.string,
  className: PropTypes.string,
}

export default H2

const StyledH2 = styled.h2`
  ${fontExtraBold()}

  font-size: 35px;
  line-height: 1.14;
  color: ${black};

  ${se &&
    se`
    text-align: left;
  `}

  ${desktop &&
    desktop`
    text-align: ${({ align }) => align && align};
  `}
`
