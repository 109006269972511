import React from "react"
import { useInView } from "react-intersection-observer"
import {  useTrail, animated } from "react-spring"
import styled from "styled-components"
import H4 from "./typography/H4"
import P from "./typography/P"
import media from "../utils/mediaQueries"
import { textLight, brandPrimary } from "../utils/colors"
import { fontBold, fontRegular } from "../utils/fontStyles"

const { se, desktop } = media

const stats = [
  {
    statistic: `20%`,
    title: `stat`,
    description: `description`,
  },
  {
    statistic: `20%`,
    title: `stat`,
    description: `description`,
  },
  {
    statistic: `20%`,
    title: `stat`,
    description: `description`,
  },
  {
    statistic: `20%`,
    title: `stat`,
    description: `description`,
  },
]

const Stats = () => {
  const [ref, inView] = useInView(ref, {
    threshold: 0.9,
  })

  const trail = useTrail(stats.length, {
    opacity: inView ? 1 : 0,
    from: { opacity: 0 },
  })

  const AnimatedStatistic = animated(Statistic)

  return (
    <StatsWrapper>
      <StatsContainer ref={ref}>
        {trail.map(({ ...rest }, index) => (
          <AnimatedStatistic key={stats[index].statistic} style={{ ...rest }}>
            <StyledP stat>
              <animated.span>{stats[index].statistic}</animated.span>
            </StyledP>
            <StyledH4>{stats[index].title}</StyledH4>
            <StyledP>{stats[index].description}</StyledP>
          </AnimatedStatistic>
        ))}
      </StatsContainer>
    </StatsWrapper>
  )
}

export default Stats

const StatsWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow: hidden;
  ${se &&
    se`
   padding: 50px 0 50px 0;
  `}
  ${desktop &&
    desktop`
  `}

`

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 2;
  ${se &&
    se`
    padding: 0 20px;
    width: 100%;
    flex-direction: row;
  `}

  ${desktop &&
    desktop`
    align-items: center;
    justify-content: space-around;
    max-width: 1200px;
    padding: 0;
  `}
`

const ImageContainer = styled.div`
  ${se &&
    se`
    width: 100%;
  `}

  ${desktop &&
    desktop`
    width: 48;
  `}
`

const Statistic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 25%;
`

const StyledH4 = styled(H4)`
  margin-bottom: 15px;
  text-align: center;
  font-size: 18px;
  ${fontBold()};
`

const StyledP = styled(P)`
  margin-bottom: 15px;
  font-size: ${({ stat }) => (stat ? `24px` : `16px`)};
  color: ${({ stat }) => (stat ? `${brandPrimary}` : `${textLight}`)};

  ${({ stat }) =>
    stat
      ? `
    ${fontBold()}
  `
      : `
  ${fontRegular()}
  `}
`
